import { Client } from "typesense";
import { env } from "$env/dynamic/public";

export function tsClient(apiKey?: string) {
  return new Client({
    nodes: [
      {
        host: env.PUBLIC_TYPESENSE_ENDPOINT,
        port: 443,
        protocol: "https",
      },
    ],
    apiKey: apiKey || env.PUBLIC_TYPESENSE_SEARCH_KEY,
  });
}

export async function queryCyclists(attendees, pageSize, page, filterBy = "id") {
  const client = tsClient();

  let searchParameters = {
    q: "*",
    query_by: "name",
    page,
    per_page: pageSize,
    filter_by: `${filterBy}:=[${attendees}]`,
  };

  const results = await client.collections("users").documents().search(searchParameters);
  return results?.hits as any;
}

export async function mentionQuery(queryText, exludeIds = [], excludeKey = "_id") {
  const client = tsClient();

  let searchParameters = {
    q: queryText,
    query_by: "name",
    page: 1,
    per_page: 5,
    limit_hits: 5,
    filter_by: exludeIds.length > 0 ? `${excludeKey}:!=[${exludeIds}]` : "",
    include_fields: "id, shortId, name, location, photoURL, tier",
  };

  const results = await client.collections("users").documents().search(searchParameters);
  return results?.hits as any;
}

export async function myRidesStravaQuery(apiKey = null, userId, lat, long, startTime) {
  const client = tsClient(apiKey);

  // Search attended rides within a time window
  const startThreshold = startTime - 3600;
  const endThreshold = startTime + 3600;

  let searchParameters = {
    q: "*",
    query_by: "title, details",
    page: 1,
    sort_by: "date:asc",
    filter_by: `date:>${startThreshold} && date:<${endThreshold} && location:(${lat}, ${long}, 10 mi) && memberDetails.userId:=${userId}`,
  };

  const results = await client.collections("rides").documents().search(searchParameters);
  return results?.hits[0] as any || null;
}
